<template>
  <div class="">
    <div class="">
      <v-card class="">
        <!-- logo -->
        <v-card-title class="d-flex py-7 position-relative">
          GALLERIE ({{galleryll.nb_element}})
        </v-card-title>

        <!-- login form -->
        <template>
          <v-row dense >
            <v-col
              v-for="(item, n) in galleryll.listEntity"
              :key="n"
              class="d-flex child-flex"
              cols="4"
            >
              <v-card
                :to="'/illustration/'+item.id" >
                <v-img
                  :src="item.src_270"
                  :lazy-src="require('@/assets/images/holder.jpg')"
                  aspect-ratio="1" contain
                  class="grey lighten-2 align-end"
                  @click="postimage = item; dialog.preview = true"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                  <!--                <v-card-actions class="text-center pa-1">
                                    <v-btn small target="_blank" :href="item.uploaddir+''+item.image" color="primary" class="v-btn" >
                                      <v-icon>{{icons.mdiDownload}}</v-icon>
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn small @click="share" class="v-btn" >
                                      <v-icon>{{icons.mdiShareVariant}}</v-icon>
                                    </v-btn>
                                  </v-card-actions>-->
                </v-img>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-card>
      <v-row v-if="loaders.loading" >
        <v-col
          v-for="n in 9"
          :key="'new-'+n"
          class="d-flex child-flex justify-space-between"
          cols="4"
        >
          <v-sheet
            :color="`grey darken-2 `"
          >
            <v-skeleton-loader
              class="d-inline-block mr-lg-2 ml-lg-2 mr-sm-1 ml-sm-1 mt-3"
              min-width="120"
              max-height="120"
              type="card"
            ></v-skeleton-loader>
          </v-sheet>
        </v-col>
      </v-row>
    </div>

    <v-dialog
      v-model="loaders.download"
      persistent
      width="300"
    >
      <v-card
        color="secondary"
      >
        <v-card-text>
          Telechargement en cours ...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiClose,
  mdiArrowRight,
  mdiShareVariant,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiDownload
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import WalletRefillComponent from '@/components/WalletRefillComponent'
import CommentComponent from "@/components/CommentComponent.vue";

export default {
  components: {CommentComponent},
  setup() {
    const galleryll = ref({next:1, listEntity:[]})
    const loaders = ref({download:false, loading: true})
    const dialog = ref({preview:false})
    const postimage = ref({ })

    const init = () => {
      loaders.value.loading = true
      Drequest.api('lazyloading.illustration?dfilters=on&dsort=id desc')
        .param({
          'next': galleryll.value.next,
          'per_page': 24,
        }).get(response => {
          console.log(response)
          loaders.value.loading = false
          galleryll.value = response
        })
    }
    init()
// Using fetch
    const downloadImage  = async (item) => {
      loaders.value.download = true
      /*const image = await fetch(item.uploaddir+''+item.image)
      const imageBlog = await image.blob()
      const imageURL = URL.createObjectURL(imageBlog)*/

      const link = document.createElement('a')
      link.href = item.uploaddir+''+item.image
      link.download = item.name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      loaders.value.download = false
    }
    const share = (item, title = 'Web Share API Draft', text = 'Take a look at this spec!') => {
      if (navigator.share) {
        navigator.share({
          title: '3AG Edition',
          text: 'Retrouvez encore plus d image sur la plateforme reader'
            +'https://reader.3ag-edition.com/gallery',
          url: item.src_270,
        })
          .then(() => console.log('Successful share'))
          .catch((error) => console.log('Error sharing', error));
      } else {
        console.log('Share not supported on this browser, do it the old way.');
      }
    }
    return {
      downloadImage,
      share,

      galleryll,
      loaders,
      dialog,
      postimage,

      icons: {
        mdiClose,
        mdiDownload,
        mdiEyeOutline,
        mdiArrowRight,
        mdiShareVariant,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
